import { graphql } from "gatsby"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import Quote from "src/components/Quote"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

const useStyles = createUseStyles({
  content: {
    width: `60%`,
    margin: `auto`,
    textAlign: `center`,
  },
  h4: {
    fontSize: `calc(0.5vh + 1rem)`,
    fontWeight: `normal`,
    marginBottom: `0.5rem`,
  },
  threeCol: {
    width: `100%`,
    marginBottom: `1em`,
  },
  threeColContainer: {
    display: `flex`,
    margin: `0px -30%`,
  },
  ul: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    listStyleType: `disc`,
    marginBottom: `1em`,
  },
  li: {
    margin: `0px 20px`,
  },
  "@media (max-width: 850px)": {
    content: {
      width: `100%`,
    },
    threeColContainer: {
      flexDirection: `column`,
      margin: `0px 0px`,
    },
  },
})

const Review = ({
  review,
  right,
}: {
  review: NonNullable<
    NonNullable<GatsbyTypes.ReviewsQuery["contentfulReviewSection"]>["reviews"]
  >[0]
  right?: boolean
}) => {
  if (review?.reviewText?.raw == null) {
    return null
  }
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        textAlign: right ? "end" : "start",
      }}
    >
      <div style={{ width: "100%", marginTop: "2em" }}>
        {documentToReactComponents(JSON.parse(review?.reviewText?.raw), {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
              <p style={{ fontSize: "18px" }}>{children}</p>
            ),
          },
        })}
        <p>
          <em>{review.guestName}</em>
        </p>
        <p style={{ marginBottom: "2em" }}>
          <em>{review.dateOfVisit}</em>
        </p>
      </div>
    </div>
  )
}

interface Props {
  data: GatsbyTypes.ReviewsQuery
}

const ReviewsPage = ({ data }: Props) => {
  const classes = useStyles()
  const [height, setHeight] = useState<number>(0)
  const section = data.contentfulReviewSection

  return (
    <Layout setHeaderHeight={setHeight}>
      <SEO title={section?.title ?? "Notes from Guests"} />
      <main
        style={{
          // height: `100vh`,
          marginTop: `${height}px`,
          padding: `6.6vmax 4vw 4vw`,
          boxSizing: "border-box",
        }}
      >
        <div className={classes.content}>
          <h1 style={{ marginBottom: 17 }}>
            {section?.title ?? "Notes from Guests"}
          </h1>
          {section?.quote != null && <Quote data={section.quote} />}

          {section?.reviews != null && (
            <div>
              {section?.reviews.map((review, i) => (
                <Review key={i} review={review} right={i % 2 === 1} />
              ))}
            </div>
          )}

          <p style={{ fontSize: "18px", fontWeight: "bold" }}>
            More notes from our guests can be found on{" "}
            <a href="https://www.houfy.com/lodging/second-breakfast/19658">
              Houfy
            </a>
          </p>
        </div>
      </main>
    </Layout>
  )
}

export default ReviewsPage

export const pageQuery = graphql`
  query Reviews {
    contentfulReviewSection {
      quote {
        ...Quote
      }
      reviews {
        guestName
        reviewText {
          raw
        }
        dateOfVisit
      }
      title
    }
  }
`
