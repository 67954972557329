import { graphql } from "gatsby"
import React from "react"

interface Props {
  data: GatsbyTypes.QuoteFragment
}

const Quote = ({ data }: Props) => {
  if (data.quote?.quote == null || data.person == null) {
    return null
  }

  return (
    <>
      <p
        style={{
          fontSize: 20,
          marginBottom: `3em`,
          fontStyle: `italic`,
          textAlign: `center`,
        }}
      >
        “{data.quote?.quote}”
        <br />- {data.person}
      </p>
    </>
  )
}

export default Quote

export const query = graphql`
  fragment Quote on ContentfulQuote {
    person
    quote {
      quote
    }
  }
`
